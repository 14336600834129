// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
if (document.querySelectorAll(".swiper-container.-hero .swiper-slide").length > 1) {
    new Swiper (".swiper-container.-hero", {
        autoplay: {
            delay: 5000,
        },
        speed: 150,
        loop: true,
    });
}

if (document.querySelectorAll(".swiper-container.-sponsors .swiper-slide").length > 1) {
    new Swiper (".swiper-container.-sponsors", {
        autoplay: {
            delay: 5000,
        },
        loop: true,
        loopedSlides: 5,
        slidesPerView: "auto",
        speed: 150,
    });
}
