// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const REGEXP = /(?:\[|%5B)at(?:\]|%5D)/;

const MAILTOS = document.querySelectorAll("a[href*='[at]'], a[href*='%5Bat%5D']");


document.addEventListener("DOMContentLoaded", () => {
    MAILTOS.forEach((mailto) => {
        mailto.setAttribute("href", mailto.getAttribute("href").replace(REGEXP, "@"));
    });

    findAndReplaceDOMText(
        document.querySelector(".page_container"),
        {
            preset: "prose",
            find: REGEXP,
            replace: "@",
        }
    );
});
